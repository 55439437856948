import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction`}</h1>
    <p>{`The Bite AI API is a RESTful JSON API. It provides access to the following products:`}</p>
    <p><strong parentName="p">{`Food Knowledge Graph`}</strong>{` - A database of food information that includes items organized in a hierarchy,
with nutrition facts, ingredients, translations, categorizations and other metadata.
It can be accessed using full text search, an autocomplete endpoint or using foreign identifiers such as barcodes or USDA ids. `}</p>
    <p><strong parentName="p">{`Food Recognition`}</strong>{` - Image recognition endpoint that detects food items in images.
Predictions from this API include references to food items from our knowledge graph.`}</p>
    <p><strong parentName="p">{`Food Logging SDK`}</strong>{` - A fully featured food logging solution that builds on top of the image recognition and knowledge graph to help automate meal tracking.
It allows you to manage users of your application, stores their meal history and provides an improved recognition system that is personalized to each user. `}</p>
    <h3>{`The API URL is`}</h3>
    <div className="gatsby-highlight" data-language="text">
  <pre className="language-text">
    <a href="https://api-beta.bite.ai/">
    <code className="language-text">
      https://api-beta.bite.ai/
    </code>
    </a>
  </pre>
    </div>
    <p>{`Please note, that the forward slash is required. If it is not included a redirect will happen and
depending on the client POST bodies may be dropped.`}</p>
    <h3>{`The API reference is located at`}</h3>
    <div className="gatsby-highlight" data-language="text">
  <pre className="language-text">
    <a href="https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/">
    <code className="language-text">
      https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/
    </code>
    </a>
  </pre>
    </div>
    <h3>{`A Swagger UI is located at`}</h3>
    <div className="gatsby-highlight" data-language="text">
  <pre className="language-text">
    <a href="https://api-beta.bite.ai/y7vtjyn4f64c/docs/swagger/">
    <code className="language-text">
      https://api-beta.bite.ai/y7vtjyn4f64c/docs/swagger/
    </code>
    </a>
  </pre>
    </div>
    <h3>{`API Client Libraries`}</h3>
    <div className="gatsby-highlight" data-language="text">
  <pre className="language-text">
    <a href="https://developer.bite.ai/docs/client-libraries/">
    <code className="language-text">
      https://developer.bite.ai/docs/client-libraries/
    </code>
    </a>
  </pre>
    </div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      